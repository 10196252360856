.popup-contianer {
    .button-container {
        border-top: 1px solid #e1e4e8;
        justify-content: space-between;
        display: flex;
        padding: 16px;
        .button-hide {
            visibility: hidden;
        }
    }
}
