@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

// font sizes :-
$header-font-size: 24px;
$extra-large-font-size: 20px;
$large-font-size: 16px;
$normal-font-size: 14px;
$small-font-size: 12px;
$xsmall-font-size: 10px;

@mixin font-light() {
    font-weight: 300;
}

@mixin font-regular() {
    font-weight: 400;
}

@mixin font-medium() {
    font-weight: 500;
}

@mixin font-bold() {
    font-weight: 700;
}

:export {
    extraLargeFontSize: $extra-large-font-size;
    largeFontSize: $large-font-size;
    normalFontSize: $normal-font-size;
    smallFontSize: $small-font-size;
}
