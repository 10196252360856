@import './styles/font.module';

//--------------------------------------------------------------------
//--------------------------------------------------------------------
//
//  Variables
//
//--------------------------------------------------------------------
//--------------------------------------------------------------------

:root {
    // spacing

    // colors

    --primary-color: #0366d6;
    --primary-hover-color: #005bc1;

    --secondary-color: #fafbfc;
    --secondary-hover-color: #f3f4f6;

    --background-color: #fafbfc;

    --text-default: #24292e;
    --text-danger: #cb2431;
    --danger-hover-bg: #cb2431;

    // font stuff

    --app-font: SF Pro Text, -apple-system, BlinkMacSystemFont, 'Roboto',
        'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
}

//--------------------------------------------------------------------
//--------------------------------------------------------------------
//
//  Global base stuff
//
//--------------------------------------------------------------------
//--------------------------------------------------------------------

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: var(--app-font);
    font-size: $large-font-size;
    color: var(--text-default);
    -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
    position: relative;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    font-size: $normal-font-size;
    background: var(--background-color);
    font-family: var(--app-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-default);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
