@import '../../../../styles/main.module.scss';

#dropdown-item {
    cursor: pointer;
    .normal-field {
        width: 100%;
        margin: 0 !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 12px !important;
    }

    &.disabled {
        cursor: default;
        pointer-events: visible;
        .normal-field span {
            color: $color-disabled;
        }
    }

    .copy-icon {
        cursor: pointer;
        background-color: white;
    }
    .label-container {
        display: flex;
        .dropdown-checkbox {
            margin-top: 3px;
            margin-right: 10px;
        }
    }
}
.custom-field-container {
    position: sticky;
    display: flex;
    width: 100%;
    padding: 8px;
    color: $color-default;
    background: $hover-secondary-bg;
    box-shadow: $border-bottom-primary-shadow;
    padding-left: 12px;
    z-index: 1;
    img {
        padding-right: 10px;
    }
    &.ignore-field {
        top: 0;
    }
    &.create-new-field {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        bottom: 0;
    }
}
