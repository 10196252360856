@import '../../../styles/main.module.scss';

.button {
    border: none;
    color: var(--text-default);
    font-family: var(--app-font);
    font-style: normal;
    padding: 6px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: $normal-font-size;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    line-height: 20px;
    background-color: var(--secondary-color);
    box-shadow: inset 0 0 0 1px rgba(27, 31, 35, 0.15);
    &:hover {
        background: var(--secondary-hover-color);
    }

    // removed , &:focus focus causing issue when we open any popup on button click and when we hide that popup button get focused without clicking on it
    &:active {
        box-shadow: inset 0.6px 0.3px rgba(27, 31, 35, 0.15);
    }

    &.primary {
        background-color: var(--primary-color);
        color: #fff;
        &:hover {
            background: var(--primary-hover-color);
        }
        &:focus {
            background-color: var(--primary-color);
        }
        &:active {
            background: var(--primary-color);
        }
    }
    &.link {
        background: none !important;
        border: none;
        padding: 0 !important;
        /*optional*/
        font-family: arial, sans-serif;
        /*input has OS specific font-family*/
        color: #069;
        text-decoration: underline;
        cursor: pointer;
        box-shadow: none;
        font-weight: normal;
    }
    &.secondary {
        color: var(--text-default);
    }
    &.success {
        background-color: #2ea44f;
        color: white;
    }
    &.danger {
        color: var(--text-danger);
        &:hover {
            color: #fff;
            background: var(--danger-hover-bg);
        }
        &:focus {
            box-shadow: 0px 0px 0px 3px rgba(203, 36, 49, 0.4);
        }
    }
    &.dark {
        background-color: $dark-button-color;
        color: #fff;
        &:hover {
            background: $dark-button-hover-color;
        }
        &:focus {
            background-color: $dark-button-hover-color;
        }
        &:active {
            background: $dark-button-hover-color;
        }
    }
    &.btn:disabled,
    button:disabled,
    &.disabled {
        opacity: 0.8 !important;
        pointer-events: none;
        color: #ffffff82;
    }

    &.large {
        padding: 10px 20px;
    }
    &.medium {
        padding: 6px 16px;
    }
    &.small {
        font-size: $small-font-size;
        line-height: 18px;
        padding: 5px 12px;
    }
}

.dropdown span {
    display: flex;
    padding-right: 16px;
    background-image: url(/images/triangle-down-dark.svg);
    background-position: center right;
    background-repeat: no-repeat;
    &.primary,
    .danger {
        background-image: url(/images/triangle-down-light.svg);
    }
}
