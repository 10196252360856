.toast-provider {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
    transform: translateY(-50%);
    width: 350px;
}
