@import '../../../styles/main.module.scss';

.error {
    .custom-dropdown {
        &__control {
            border-color: $color-error !important;
            border: 1px solid $color-error !important;
            border-radius: 6px;
            &:hover,
            &:active {
                border-color: $color-error !important;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.updated {
    .custom-dropdown {
        &__control {
            border-color: $updated !important;
            border: 1px solid $updated !important;
            border-radius: 6px;
            &:hover,
            &:active {
                border-color: $updated !important;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.sm {
    .custom-dropdown {
        &__control {
            height: 28px;
            font-size: $normal-font-size;
            min-height: 28px !important;
            background: $table-background-color !important;
        }
        &__single-value {
            color: $color-default !important;
            font-size: $small-font-size;
            font-weight: 600;
            font-style: normal;
        }
    }
}

.custom-dropdown {
    &__control {
        height: 33px;
        font-size: $normal-font-size;
        min-height: 33px !important;
        cursor: pointer !important;
        box-shadow: none !important;
        border-color: $input-border-color !important;
        &:hover,
        &:active {
            border-color: $input-border-color !important;
        }
    }

    &__indicator {
        padding: 3px !important;
    }

    &__menu {
        font-size: $small-font-size;
    }

    &__menu-list {
        // max-height: 200px !important; uncomment if needs to provide fix max height
        padding: 0px !important;
        #dropdown-item {
            border-bottom: $input-border;
            font-size: $small-font-size;
        }
    }
    &__input-container {
        margin: 0px !important;
    }
    &__placeholder {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $color-default !important;
    }
}

.search-icon {
    .custom-dropdown {
        &__value-container {
            background-image: url(../../../images/icon_search_dark.svg) !important;
            background-repeat: no-repeat !important;
            background-position: left 8px center !important;
            padding-left: 25px !important;
        }
    }
}

#dataImportDropdown {
    .icon-image {
        cursor: pointer;
        margin-bottom: 5px;
    }
}
