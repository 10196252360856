.loader-container {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(0 0 0 / 21%);
    // rgb(0 0 0 / 21%) / rgba(0, 0, 0, 0.5)
    z-index: 1000;
    .loader {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99999;
        left: 0px;
        top: 43%;
        display: flex;
        flex-direction: column;
        align-self: center;
        background-color: transparent;
        svg {
            align-self: center;
            height: 50px;
            width: 50px;
            animation: spin 1.2s linear infinite;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
