@import '../../../styles/main.module.scss';
.popup {
    z-index: 1 !important; // if we remove this then dropdown options get hidden and not showing
    .MuiDialog-paper {
        overflow-y: visible;
    }
    .max-size-popup {
        max-width: 1082px;
    }
}

.full-width-popup {
    .MuiDialog-paper {
        width: 100%;
    }
    .max-size-popup {
        max-width: none;
    }
}
