@import '../../../styles/main.module.scss';

.covision-toast {
    box-shadow: 1px 1px 8px 0px #0000001f;
    background-color: white;
    display: inline-block;
    overflow: hidden;
    border-radius: 6px;
    color: $color-default;
    .wrap {
        display: flex;
        min-height: 48px;
        .icon {
            background-color: var(--primary-color);
            width: 48px;
            flex-shrink: 0;
            overflow: hidden;
            &::before {
                content: '';
                background-image: url(/images/icon_information.svg);
                background-repeat: no-repeat;
                background-position: center center;
                height: 100%;
                display: block;
            }
        }
        span {
            flex: 4;
            padding: 16px;
            display: block;
        }
        .close-icon {
            content: '';
            background-image: url(/images/icon_close.svg);
            background-repeat: no-repeat;
            background-position: center center;
            width: 48px;
            cursor: pointer;
            flex-shrink: 0;
        }
    }

    &.success {
        .wrap {
            .icon {
                background-color: #28a745;
                &::before {
                    background-image: url(/images/icon_success.svg);
                }
            }
        }
    }
    &.error {
        .wrap {
            .icon {
                background-color: #eb5757;
                &::before {
                    background-image: url(/images/icon_error.svg);
                }
            }
        }
    }
    &.warning {
        .wrap {
            .icon {
                background-color: #f9c513;
                &::before {
                    background-image: url(/images/icon_warning.svg);
                }
            }
        }
    }
    &.loading {
        .wrap {
            .icon {
                background-color: #586069;
                &::before {
                    background-image: url(/images/icon_loading.svg);
                    animation: spin 1.2s linear infinite;
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
