@import '../../styles/main.module.scss';

.custom-tooltip {
    background: #24292e;
    color: #fff;
    padding: 6px 9px;
    line-height: 18px;
    font-size: $small-font-size;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    max-width: 300px;
    word-wrap: break-word;
    p {
        margin: 0;
    }
    &::after {
        content: '';
        display: inline-block;
        background: #24292e;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        position: absolute;
    }
    &.north {
        &::after {
            left: 50%;
            margin-left: -6px;
        }
    }
    &.northeast {
        &::after {
            left: 12px;
        }
    }
    &.east {
        &::after {
            left: -5px;
            bottom: 50%;
            margin-bottom: -6px;
        }
    }
    &.southeast {
        &::after {
            left: 12px;
            top: -5px;
        }
    }
    &.south {
        &::after {
            left: 50%;
            top: -5px;
            margin-left: -6px;
        }
    }
    &.southwest {
        &::after {
            right: 12px;
            top: -5px;
            margin-left: -6px;
        }
    }
    &.west {
        &::after {
            right: -5px;
            bottom: 50%;
            margin-bottom: -6px;
        }
    }
    &.northwest {
        &::after {
            right: 12px;
            bottom: -5px;
        }
    }
}
