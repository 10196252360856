$color-font: rgb(76, 73, 91);
$color-font-section: rgb(158, 171, 190);

$color-white: #ffffff;

$gradient-from: rgb(80, 206, 200);
$gradient-to: rgb(19, 191, 211);

$color-tab-inactive: rgb(158, 171, 190);

$color-primary: rgb(19, 191, 211);
$color-error: #ff2800;
$color-error-2: #cb2431;

$color-disabled: #959da5;
$color-default: #24292e;
$color-for-input: #6a737d;

$color-blue: #0366d6;
$color-yellow: #b08800;
$color-green: #28a745;
$color-subdued: #6d7175;

$scroll-bar-color: #ced7e0;

$input-shadow: inset 0px 1px 2px rgba(27, 31, 35, 0.075);

$input-border: 1px solid #e1e4e8 !important;
$input-active-border: 1px solid $color-blue !important;
$input-label-color: hsla(200, 4%, 13%, 0.502);
$input-shadow: inset 0px 2px 0px hsla(214, 13%, 90%, 0.2);
$input-required-color: #006eed;
$input-outline-color: #54a3ff;

$hover-primary-bg: #f1f8ff;
$hover-secondary-bg: #f6f8f7;
$border-bottom-primary-shadow: inset 0px -1px 0px #e1e4e8;

$input-border-color: #e1e4e8;

$table-border-color: #d1d5da;
$table-background-color: #fafbfc;
$table-border: 1px solid $table-border-color;
$table-sorted-cell-background-color: rgba(196, 196, 196, 0.12);

$created: rgba(40, 167, 69, 1);
$updated: rgba(3, 102, 214, 1);
$no-change: rgba(106, 115, 125, 1);
$total-records: rgba(106, 115, 125, 1);
$skyblue: #54a3ff;
$clickable-item: #0366d5;
$dark-button-color: #474747;
$dark-button-hover-color: #191e20;
$label-bg-color: #f9c513;
$icon-bg-color: #f6f7f9;
$subtitle-color: #bebebe;

:export {
    tableBackGroundColor: $table-background-color;
    tableBorder: $table-border;
    commonBorder: $input-border;
    created: $created;
    updated: $updated;
    noChange: $no-change;
    totalRecords: $total-records;
    errors: $color-error;
    skyblue: $skyblue;
    disabled: $color-disabled;
    colorBlue: $color-blue;
    colorWhite: $color-white;
    colorYellow: $color-yellow;
    colorGreen: $color-green;
    colorDefault: $color-default;
    tableSortedCellBackgroundColor: $table-sorted-cell-background-color;
}

$label-default-background: #eaf5ff;
$label-default-color: #586069;
$detail-item-label-color: #d89a9a;
$active-dropdown-color: #ededed;
