@import '../../../styles/main.module.scss';

.label {
    padding: 3px 12px;
    font-size: $normal-font-size;
    display: inline-block;
    .info-icon {
        width: 13px !important;
        height: 13px !important;
        pointer-events: auto;
        cursor: auto;
    }
    span {
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #24292e;
        word-break: break-word;
    }
    &.solid {
        background: #e7f3ff;
        border: 1px solid #e7f3ff;
        span {
            color: #0366d6;
        }
    }
    &.outline {
        padding: 0px;
        span {
            color: $input-required-color;
            font-weight: 400;
        }
    }

    &.small-link {
        padding: 0px;
        cursor: pointer;
        span {
            color: $color-for-input;
            font-weight: 400;
            font-size: $small-font-size;
            &:hover {
                color: $input-required-color;
                text-decoration: underline;
            }
        }
    }

    @mixin normal {
        padding: 0px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        span {
            color: $color-default;
        }
    }
    &.normal {
        @include normal();
    }
    &.active {
        @include normal();
        span {
            color: $color-blue;
        }
    }
    &.with-padding {
        padding-bottom: 8px;
    }
    &.label-for-input {
        @include normal();
        span {
            color: $color-for-input;
            font-weight: normal;
        }
    }
    &.no-padding {
        padding-bottom: 0px !important;
    }
    &.label-for-subtitle {
        @include normal();
        span {
            color: $subtitle-color;
            font-weight: 600;
        }
    }
    &.covision-input-label {
        @include normal();
        padding-bottom: 10px;
        span {
            color: $input-label-color;
            font-weight: normal;
        }
    }

    &.error {
        @include normal();
        span {
            color: $color-error;
            font-weight: normal;
            font-size: $small-font-size;
        }
    }
    &.label-secondary {
        @include normal();
        span {
            color: $color-default;
            font-weight: normal;
        }
    }
    &.label-thired {
        @include normal();
        span {
            color: $color-default;
            font-weight: 400;
        }
    }
    &.label-small {
        @include normal();
        span {
            color: $color-default;
            font-weight: 400;
            font-size: $small-font-size;
        }
    }
}
